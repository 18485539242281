import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { API } from "./faApi";

export const ApiService = {
  findMapThings: (accountId, callback, errorCallback) => {
    var req = requests.getSimpleGetRequest(API.findMapThings + "?accountId=" + accountId);

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {};
  },
  getThingDynamicData: (accountId, id, classId, callback, errorCallback) => {
    const req = requests.getSimpleGetRequest(API.getThingDynamicData, {
      accountId,
      id,
      classId,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
  findThingLinks: (accountId, id, callback, errorCallback) => {
    const req = requests.getSimpleGetRequest(API.findThingLinks, {
      accountId,
      id,
    });

    FaAxios(req)
      .then((response) => {
        callback(response.data);
      })
      .catch((error) => {
        errorCallback(error?.response?.data);
      });
    return () => {
      //setLoading(false);
    };
  },
};
