import makeStyles from "@mui/styles/makeStyles";

export const useStyles = makeStyles((theme) => ({
  ThingDetailImage: {
    width: "100%",
    objectFit: "scale-down",
  },
  ThingDetailLabelHeader: {
    fontSize: "12px",
    lineHeight: 1.42857,
    //borderTop: "1px solid #ddd",
    borderBottom: "1px solid #cfdae0",
    fontWeight: 400,
    color: "#9A9A9A",
  },
  ThingListImage: {
    width: "48px",
    height: "48px",
    marginRight: "10px",
    borderRadius: "15%",
  },

  ThingListImageSm: {
    width: "16px",
    height: "16px",
    marginRight: "10px",
    borderRadius: "15%",
  },
}));
