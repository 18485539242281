import Qs from "qs";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import FaDataTable from "react-base-fa/dist/fa/faDataTable";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const ThingsDataTable = forwardRef((props, ref) => {
  let history = useHistory();

  const calcQueryParams = () => {
    let tmp = qsParse(history.location.search);
    if (props.queryParams) return { ...props.queryParams, ...tmp };
    else return { ...tmp };
  };

  const [queryParams, setQueryParams] = useState(calcQueryParams());

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedDatas, setSelectedDatas] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [dataSize, setDataSize] = useState(10);
  const [orderType, setOrderType] = useState(queryParams.isAsc ? "asc" : "dsc");
  const [orderField, setOrderField] = useState(queryParams.sort);
  const [lastSearchForm, setLastSearchForm] = useState(null);

  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    //dispatch(snackbar(snackBarObj));
  };
  useImperativeHandle(ref, () => ({
    clearSelectedRows() {
      onSelectedChange([]);
    },

    refreshTable() {
      refresh(calcQueryParams());
    },

    resetPage() {
      onPageChange(null, 0);
    },

    getQueryParams() {
      return { ...queryParams, totalItems: dataSize };
    },

    search(searchForm) {
      let newQueryParams = { ...queryParams, page: 0 };
      setQueryParams(newQueryParams);

      if (props.queryParams) {
        //refresh(newQueryParams);
        searchAction(searchForm, newQueryParams);
      } else {
        let tmp = { ...newQueryParams, sf: searchForm };
        let search = "?" + Qs.stringify(tmp, { skipNulls: true });
        if (search !== history.location.search) {
          const tmpTo = {
            pathname: history.location.pathname,
            search: search,
          };
          history.push(tmpTo);
        }

        searchAction(searchForm, newQueryParams);
      }
    },
  }));

  const onPageChange = (event, newPage) => {
    let newQueryParams = { ...queryParams, page: newPage };
    let tmp = qsParse(history.location.search);
    if (tmp.sf) newQueryParams = { ...newQueryParams, sf: tmp.sf };

    setQueryParams(newQueryParams);
    if (props.queryParams) {
      refresh(newQueryParams);
    } else {
      let search = "?" + qsStringfy2(newQueryParams);
      if (search !== history.location.search) {
        const tmpTo = {
          pathname: history.location.pathname,
          search: search,
        };
        history.push(tmpTo);
      }
    }
  };

  const onRowsPerPageChange = (size) => {
    setPageSize(size);
    let newQueryParams = { ...queryParams, size: size };
    let tmp = qsParse(history.location.search);
    if (tmp.sf) newQueryParams = { ...newQueryParams, sf: tmp.sf };
    setQueryParams(newQueryParams);

    if (props.queryParams) {
      refresh(newQueryParams);
    } else {
      let search = "?" + qsStringfy2(newQueryParams);
      if (search !== history.location.search) {
        const tmpTo = {
          pathname: history.location.pathname,
          search: search,
        };
        history.push(tmpTo);
      }
    }
  };

  const onColumnSort = (event, orderBy, order) => {
    setOrderField(orderBy);
    setOrderType(order);
    let newQueryParams = {
      ...queryParams,
      sort: orderBy,
      isAsc: order === "asc",
    };

    let tmp = qsParse(history.location.search);
    if (tmp.sf) newQueryParams = { ...newQueryParams, sf: tmp.sf };

    setQueryParams(newQueryParams);

    if (props.queryParams) {
      refresh(newQueryParams);
    } else {
      let search = "?" + qsStringfy2(newQueryParams);
      if (search !== history.location.search) {
        const tmpTo = {
          pathname: history.location.pathname,
          search: search,
        };
        history.push(tmpTo);
      }
    }
  };

  const onSelectedChange = (selectedIndexes) => {
    let selectedArray = [];
    selectedIndexes.map((item) => {
      selectedArray.push(rows[item]);
    });
    setSelectedRows(selectedIndexes);
    setSelectedDatas(selectedArray);
    if (props.onSelectedChange) props.onSelectedChange(selectedArray);
  };

  const refresh = (newQueryParams) => {
    let finalQueryParams = newQueryParams ? newQueryParams : queryParams;
    setLoading(true);

    if (props.data) {
      setRows(props.data);
      setDataSize(props.data.length);
      setPageSize(props.data.length);
      setLoading(false);
    } else if (props.dataUrl) {
      setLastSearchForm(finalQueryParams);
      var req = requests.getSimpleGetRequest(props.dataUrl, finalQueryParams);
      FaAxios(req)
        .then((response) => {
          if (!loading) {
            let rownumber = 1;
            response.data.list.map((element) => {
              element["rownumber"] = rownumber++;
              return element;
            });

            setRows(response.data.list);
            setDataSize(response.data.totalItems);
            setPageSize(parseInt(finalQueryParams.size));
            setLoading(false);
          }
        })
        .catch((error) => {
          showAlert("error", error);
          setLoading(false);
        });
      return () => {
        setLoading(false);
      };
    } else if (props.searchUrl) {
      searchAction(props.searchForm, finalQueryParams);
    }
  };

  const cleanQueryParams = (params) => {
    if (Array.isArray(params)) {
      // Eğer bir dizi ise boş dizileri filtrele
      return params.filter((item) => item !== null && item !== "");
    } else if (typeof params === "object" && params !== null) {
      // Eğer bir nesne ise, her bir key'i kontrol et
      return Object.fromEntries(
        Object.entries(params)
          .map(([key, value]) => [key, cleanQueryParams(value)]) // Değerleri rekürsif olarak temizle
          .filter(
            ([_, value]) =>
              value !== null &&
              value !== "" &&
              !(Array.isArray(value) && value.length === 0)
          ) // Null, boş string ve boş dizileri filtrele
      );
    }
    // Diğer değerler (örneğin stringler) direkt döndürülür
    return params;
  };

  const qsStringfy2 = (queryParams) => {
    // queryParams'daki null ve boş string değerleri kaldırıyoruz
    const cleanedParams = cleanQueryParams(queryParams); // Parametreleri temizle
    return Qs.stringify(cleanedParams, {
      arrayFormat: "indices",
      allowDots: true,
    });
  };
  const refreshAddressBar = (queryParams, searchForm) => {
    let search;
    if (searchForm) {
      search = "?" + qsStringfy2({ ...queryParams, sf: searchForm });
    } else search = "?" + qsStringfy2(queryParams);

    if (
      search !== history.location.search &&
      history.location.search != null &&
      history.location.search.indexOf("page=") > -1
    ) {
      const tmpTo = {
        pathname: history.location.pathname,
        search: search,
      };
      history.push(tmpTo);
    }
  };

  const searchAction = (searchForm, newQueryParams) => {
    let finalQueryParams = newQueryParams ? newQueryParams : queryParams;
    setLoading(true);
    if (props.data) {
      setRows(props.data);
      setDataSize(props.data.length);
      setPageSize(props.data.length);
      setLoading(false);
      refreshAddressBar(finalQueryParams);
    } else {
      refreshAddressBar(finalQueryParams, searchForm);
      let s = {
        ...searchForm,
        paging: {
          ...finalQueryParams,
          asc: finalQueryParams.isAsc,
        },
      };
      setLastSearchForm(s);
      var req = requests.getSimplePostRequest(props.searchUrl, s);
      FaAxios(req)
        .then((response) => {
          if (!loading) {
            let rownumber = 1;
            response.data.list.map((element) => {
              element["rownumber"] = rownumber++;
              return element;
            });
            setRows(response.data.list);
            setDataSize(response.data.totalItems);
            setPageSize(parseInt(finalQueryParams.size));
            setLoading(false);
          }
        })
        .catch((error) => {
          showAlert("error", error);
          setLoading(false);
        });
      return () => {
        setLoading(false);
      };
    }
  };

  useEffect(() => {
    let newQueryParams = props.queryParams
      ? queryParams
      : qsParse(history.location.search);
    refresh(newQueryParams);
    //history.location.search,
  }, [props.data]);

  const exportToExcel = (searchForm) => {
    let newQueryParams = { ...queryParams, page: 0 };
    setQueryParams(newQueryParams);

    let search = "?" + qsStringfy2(newQueryParams);
    if (search !== history.location.search) {
      const tmpTo = {
        pathname: history.location.pathname,
        search: search,
      };
      history.push(tmpTo);
    }

    let s = {
      ...lastSearchForm,
    };
    var req = requests.getSimplePostRequest(props.exportUrl, s);
    FaAxios(req)
      .then((response) => {
        if (!loading) {
          const link = document.createElement("a");
          link.href =
            "data:application/octet-stream;base64," + response.data.content;
          link.setAttribute("download", response.data.fileName);
          document.body.appendChild(link);
          link.click();
          setLoading(false);
        }
      })
      .catch((error) => {
        showAlert("error", error);
        setLoading(false);
      });
    return () => {
      setLoading(false);
    };
  };

  // const {
  //   columns,
  //   data,
  //   rowKey,
  //   showDeleteButton,
  //   onDeleteAction,
  //   showEditButton,
  //   onEditAction,
  //   showPaging,
  //   activePage,
  //   //pageSize,
  //   //dataSize,
  //   rowsPerPageTemplate,
  //   onPageChange,
  //   onRowsPerPageChange,
  //   showLoading,
  //   showError,
  //   errorMessage,
  //   showHeaderText,
  //   headerText,
  //   onColumnSort,
  //   orderType,
  //   orderField,
  //   headerAction,
  //   zebra,
  //   faSelectedRows,
  //   faSetSelectedRows,
  //   faMultiSelect,
  //   dataUrl,
  // } = props;

  const DataTableRender = () => {
    return (
      <>
        <FaDataTable
          showEditButton={props.showEditButton}
          onEditAction={(event, data) => {
            if (props.onEditAction) {
              props.onEditAction(data);
            }
          }}
          showHeaderText={props.showHeaderText}
          showDeleteButton={props.showDeleteButton}
          showHeader={props.showHeader}
          onDeleteAction={(event, data) => {
            if (props.onDeleteAction) {
              props.onDeleteAction(data);
            }
          }}
          showLoading={loading}
          headerText={props.headerText}
          columns={props.columns}
          data={rows}
          showPaging={props.showPaging == null ? true : props.showPaging}
          activePage={parseInt(queryParams.page)}
          pageSize={pageSize}
          rowsPerPageTemplate={[10, 25, 50, 100]}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          dataSize={dataSize}
          orderType={orderType}
          orderField={orderField}
          onColumnSort={onColumnSort}
          rowKey={props.rowKey ? props.rowKey : "id"}
          faSelectedRows={props.onSelectedChange && selectedRows}
          faSetSelectedRows={props.onSelectedChange && onSelectedChange}
          faMultiSelect={!!props.onSelectedChange}
          faOnDoubleClick={(index) => {
            if (props.faOnDoubleClick) {
              props.faOnDoubleClick(rows[index], index);
            }
          }}
          faExcelExport={props.exportUrl && exportToExcel}
          columnClassName={props.columnClassName}
          faDetailRows={props.faDetailRows}
          faDetailRender={props.faDetailRender}
        />
      </>
    );
  };

  return DataTableRender();
});
export default ThingsDataTable;
