import {
  clusterCountLayer,
  clusterLayer,
  unclusteredPointLayer,
} from "features/thingsMap/layers";
import React, { useEffect, useRef, useState } from "react";
import { qsParse } from "react-base-fa/dist/services/utils";
import Map, { GeolocateControl, Layer, Popup, Source } from "react-map-gl";
import { useHistory } from "react-router-dom";
import { ApiService } from "services/ApiService";
import { MAPBOX_TOKEN } from "services/utils/Constants";
import PublicMapThingDetail from "./PublicMapThingDetail";

const geolocateStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  margin: 10,
};

export default function PublicMap() {
  let history = useHistory();
  let tmp = qsParse(history.location.search);

  const accountId = tmp?.id;

  const mapRef = useRef();

  const [thingsList, setThingsList] = useState([]);

  useEffect(() => {
    ApiService.findMapThings(accountId, findMapThingsOnSuccess, (error) => {});
  }, []);

  const parseLocation = (gpsLocation) => {
    if (gpsLocation && gpsLocation.length > 3) {
      let parsedData = JSON.parse(gpsLocation);
      if (parsedData.lat && parsedData.lng) {
        return parsedData;
      } else if (parsedData.latitude && parsedData.longitude) {
        return { lat: parsedData.latitude, lng: parsedData.longitude };
      }
    }

    return null;
  };

  const findMapThingsOnSuccess = (data) => {
    const temp = data.map((item) => {
      var location = parseLocation(item.gpsLocation);
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [location.lng, location.lat],
        },
        properties: {
          id: item.id,
        },
      };
    });

    setGeojson({
      type: "FeatureCollection",
      features: temp,
    });

    setThingsList(data);
  };

  const [viewport, setViewPort] = useState({
    latitude: 39.862645729977174,
    longitude: 32.738810051232576,
    zoom: 6,
    bearing: 0,
    pitch: 0,
  });

  const onClick = (event) => {
    if (
      event &&
      event.features &&
      event.features[0] &&
      event.features[0].properties &&
      event.features[0].properties.id
    ) {
      const feature = event.features[0];

      setPopupInfo({
        id: feature.properties.id,
        lng: event.lngLat.lng,
        lat: event.lngLat.lat,
        thing: thingsList.find((item) => item.id === feature.properties.id),
      });
    } else if (
      event &&
      event.features &&
      event.features[0] &&
      event.features[0].properties &&
      event.features[0].properties.cluster_id
    ) {
      const feature = event.features[0];
      const clusterId = feature.properties.cluster_id;

      let map = mapRef.current.getMap();
      const mapboxSource = map.getSource("things");

      mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          return;
        }

        setViewPort({
          ...viewport,
          latitude: feature.geometry.coordinates[1],
          longitude: feature.geometry.coordinates[0],
          zoom: zoom,
        });

        map.easeTo({
          center: feature.geometry.coordinates,
          zoom,
          duration: 500,
        });
      });
    }
  };

  const [geojson, setGeojson] = useState({
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [32.685632575303316, 39.882920037461844],
        },
      },
    ],
  });

  const [popupInfo, setPopupInfo] = useState(null);

  return (
    <>
      <div style={{ height: "100vh", overflow: "hidden" }}>
        <Map
          {...viewport}
          width="100%"
          height="100%"
          //mapStyle="mapbox://styles/mapbox/streets-v11"
          mapStyle="mapbox://styles/mapbox/light-v10"
          onMove={(evt) => setViewPort(evt.viewState)}
          mapboxAccessToken={MAPBOX_TOKEN}
          onClick={onClick}
          ref={mapRef}
          interactiveLayerIds={[unclusteredPointLayer.id, clusterLayer.id]}
        >
          <GeolocateControl
            style={geolocateStyle}
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
          />

          <Source
            id="things"
            type="geojson"
            data={geojson}
            cluster={true}
            clusterMaxZoom={14}
            clusterRadius={50}
          >
            <Layer {...clusterLayer} />
            <Layer {...clusterCountLayer} />
            <Layer {...unclusteredPointLayer} />
          </Source>

          {popupInfo && (
            <Popup
              anchor="top"
              longitude={Number(popupInfo.lng)}
              latitude={Number(popupInfo.lat)}
              closeOnClick={false}
              onClose={() => setPopupInfo(null)}
              maxWidth={"400px"}
            >
              <PublicMapThingDetail
                thing={popupInfo.thing}
                accountId={accountId}
              />
            </Popup>
          )}
        </Map>
      </div>
    </>
  );
}
