import { Avatar, Card, CardContent, Grid, Link, Typography } from "@mui/material";
import DynamicFieldsDetail from "components/dynamicFieldsDetail";
import { default as React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiService } from "services/ApiService";
import { THING } from "services/faApi";
import { BASE_URL } from "serviceWorker";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";
import { useStyles } from "./ThingDetailCardStyle";

export default function PublicMapThingDetail(props) {
  const { thing, accountId } = props;
  const { t } = useTranslation("thingDetail", { i18n });
  const classes = useStyles();
  const labelClasses = labelStyles();

  const [dynamicData, setDynamicData] = useState({});

  const [dynamicFormList, setDynamicFormList] = useState([]);
  const [dynamicValues, setDynamicValues] = useState([]);

  const [linkList, setLinkList] = useState([]);

  useEffect(() => {
    if (thing) {
      ApiService.getThingDynamicData(
        accountId,
        thing.id,
        thing.thingClassId,
        (data) => {
          if (data) {
            setDynamicFormList(data.fieldList);
            setDynamicValues(data.data.detailData ? data.data.detailData : []);
          } else {
            setDynamicFormList([]);
            setDynamicValues([]);
          }
        },
        (error) => {}
      );

      ApiService.findThingLinks(accountId, thing.id, setLinkList, (error) => {
        setLinkList([]);
      });
    }
  }, [thing]);

  const getImageSrc = () => {
    if (thing && thing.thumbnailUrl) {
      return BASE_URL + THING.thumbnail + "/" + thing.id + "/" + thing.thumbnailUrl;
    } else if (thing && thing.thumbnail) {
      return `data:image/png;base64, ${thing.thumbnail}`;
    }
  };

  const renderThing = (value) => {
    return (
      <>
        <Link
          href={thing.thidUrl}
          target="_blank"
          style={{
            cursor: "pointer",
          }}
          underline="hover"
        >
          {value && (value.thumbnailUrl || value.thumbnail) && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "2%",
              }}
            >
              <img alt="" src={getImageSrc()} className={classes.ThingListImage} />{" "}
              <Typography className={labelClasses.LabelValue}>{thing.description}</Typography>
            </div>
          )}
          {value && !(value.thumbnailUrl || value.thumbnail) && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "2%",
              }}
            >
              <Avatar variant="square" className={classes.ThingListImage}>
                {" "}
                {thing.description.charAt(0)}
              </Avatar>{" "}
              <Typography className={labelClasses.LabelValue}>{thing.description}</Typography>
            </div>
          )}
        </Link>
      </>
    );
  };

  return (
    <Card className="fai-temelBilgiler">
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <Grid item container spacing={1}>
              <Grid item xs={12} className={classes.ThingDetailLabelHeader}>
                {thing && thing.id && renderThing(thing)}
              </Grid>
              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}>{t("CLASS_NAME")}</Typography>
                <Typography className={labelClasses.LabelValue}>{thing?.className}</Typography>
              </Grid>

              <Grid item xs={6} className={labelClasses.LabelRow}>
                <Typography className={labelClasses.LabelHeader}></Typography>
                <Typography className={labelClasses.LabelValue}>{thing?.hashtag}</Typography>
              </Grid>
              {linkList && linkList.length > 0 && (
                <Grid item xs={12} className={labelClasses.LabelRow}>
                  {linkList.map((item, index) => {
                    return (
                      <div>
                        <a href={item.url} target="_blank">
                          {item.description && item.description.length > 0 ? item.description : item.url}
                        </a>
                      </div>
                    );
                  })}
                </Grid>
              )}
              <Grid item xs={12} className={labelClasses.LabelRow}>
                <DynamicFieldsDetail
                  selectedDetails={dynamicFormList}
                  details={dynamicFormList}
                  data={dynamicValues}
                  objectId={thing?.id}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
